<template>
  <b-sidebar
    id="sidebar-add-eligibility"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @hidden="resetHandler()"
    @shown="init()"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Eligibility
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- form -->
      <validation-observer
        ref="ticketValidation"
      >
        <!-- Body -->

        <b-form
          ref="ticketForm"
          class="p-2"
          @submit.prevent
        >

          <!-- Project -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Insurance"
              :rules="required_str"
            >
              <b-form-group
                label="Insurance Type"
                label-for="insurance"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="insurance"
                  v-model="eligibility.insurance"
                  item-text="text"
                  item-value="value"
                  :options="catalogLists.insurance"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Category -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Medical Group"
              :rules="required_str"
            >
              <b-form-group
                label="Medical Group"
                label-for="medicalGroup"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="medicalGroup"
                  v-model="eligibility.ipa"
                  item-text="text"
                  item-value="value"
                  :options="catalogLists.ipa"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- SubCategory -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="pcp"
              :rules="required_str"
            >
              <b-form-group
                label="Primary Care Provider"
                label-for="pcp"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="pcp"
                  v-model="eligibility.pcp"
                  item-text="text"
                  item-value="value"
                  :options="catalogLists.pcp"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- SubSubCategory -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="hp"
              :rules="required_str"
            >
              <b-form-group
                label="Health Plan"
                label-for="hp"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="hp"
                  v-model="eligibility.hp"
                  item-text="text"
                  item-value="value"
                  :options="catalogLists.hp"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              :key="buttonKey"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              :disabled="!isAtLeastOneFieldSelected()"
              type="submit"
              @click.once="validationForm()"
            >
              Save
            </b-button>

            <b-button
              ref="closeSideBarTickets"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar, BForm, BFormGroup, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import shareServices from '@core/services/share/shareService'
// import moment from 'moment'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BButton,
    vSelect,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      imgFile: [],
      eligibility: {
        ipa: null, // most be: { Id: Number, Name: String }
        hp: null, // most be: { Id: Number, Name: String }
        insurance: null, // most be: { Id: Number, Name: String }
        pcp: null, // most be: { Id: Number, Name: String }
      },
      catalogLists: {
        ipa: [], // most be Array of: { Id: Number, Name: String }
        hp: [], // most be Array of: { Id: Number, Name: String }
        insurance: [], // most be Array of: { Id: Number, Name: String }
        pcp: [], // most be Array of: { Id: Number, Name: String }
      },
      buttonKey: 1,
      // validation rules
      required,
      required_str: 'required',
      permissionAdmin: false,
    }
  },
  watch: {
    eligibility: {
      handler() {
        this.required_str = this.isAtLeastOneFieldSelected() ? '' : 'required'
      },
      deep: true,
    },
  },
  methods: {
    init() {
      this.buttonKey += 1
      this.getCatalogs()
      this.getPermissions()
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    getPermissions() {
      const profile = this.$store.getters['user-auth/getStaffProfile']
      // const user = this.$store.getters['user-auth/getUser']
      if (profile === 1) this.permissionAdmin = true
    },
    /* eslint-disable no-param-reassign */
    validationForm() {
      this.$refs.ticketValidation.validate().then(success => {
        if (success) {
          console.log(this.eligibility)
          const dataRequest = {
            id_ipa: this.eligibility.ipa?.value || 0,
            id_pcp: this.eligibility.pcp?.value || 0,
            id_insurance_type: this.eligibility.insurance?.value || 0,
            id_hp: this.eligibility.hp?.value || 0,
            id_partners_patients: this.id,
          }
          shareServices.setEligibility(dataRequest).then(() => {
            this.$refs.closeSideBarTickets.click()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Eligibility has been set',
                icon: 'InboxIcon',
                variant: 'success',
              },
            })
            this.resetHandler()
            this.refreshHandler()
          }).catch(error => {
            this.buttonKey += 1
            this.toastErrorHandler(error)
          })
        }
      })
    },
    isAtLeastOneFieldSelected() {
      // Check if at least one field is selected
      return (
        this.eligibility.ipa !== null
        || this.eligibility.hp !== null
        || this.eligibility.insurance !== null
        || this.eligibility.pcp !== null
      )
    },
    resetHandler() {
      this.eligibility = {
        ipa: null, // most be: { Id: Number, Name: String }
        hp: null, // most be: { Id: Number, Name: String }
        insurance: null, // most be: { Id: Number, Name: String }
        pcp: null, // most be: { Id: Number, Name: String }
      }
      this.resetFormTicket()
    },
    resetFormTicket() {
      this.$refs.ticketValidation.reset()
    },
    async getSpecificCatalog(catalog) {
      return shareServices.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const { data } = response.data
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    /* eslint-enable no-param-reassign */
    // getFile(url) {
    //   if (!url) return
    //   const fullPath = ticketsServices.getUrlFile(url.replace('./', '/'))
    //   window.open(fullPath, '_blank')
    // },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  </style>
