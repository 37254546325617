<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-action"
    ref="modal-action"
    :title="Title"
    no-close-on-backdrop
    size="lg"
    ok-title="Submit"
    cancel-variant="outline-secondary"
    scrollable
    @hidden="resetModal"
    @show="init"
    @ok="handleOk"
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        />
      </div>
      <div
        style=""
      >
        <h3>{{ profile.fullname }}</h3>
        <h6 class="text-muted">
          {{ profile.phone | phone-format }}
        </h6>
      </div>
      <hr class="mb-2">

      <!-- Add the two selects for Action Outcomes and Stage -->
      <validation-observer
        ref="statusValidation"
      >
        <b-form
          ref="form"
          @submit.prevent
        >
          <!-- action -->
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="action"
              rules="required"
            >
              <b-form-group
                label="Action"
                label-for="action"
                class="text-left"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="action"
                  v-model="actionOutcome"
                  :options="catalogLists.actions"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="isRescheduled"
          >
            <validation-provider
              #default="{ errors }"
              name="interaction"
              rules="required"
            >
              <b-form-group
                label="Interaction"
                label-for="interaction"
                class="text-left"
                :state="errors.length > 0 ? false:null"
              >
                <v-select
                  id="interaction"
                  v-model="interaction"
                  :options="catalogLists.interaction"
                  label="text"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="isRescheduled"
          >
            <validation-provider
              #default="{ errors }"
              name="date"
              rules="required"
            >
              <b-form-group
                label="Schedule Time"
                label-for="date"
                class="text-left"
                :state="errors.length > 0 ? false:null"
              >
                <flat-pickr
                  id="date"
                  v-model="schedule_date"
                  class="form-control"
                  :config="{ enableTime: true, dateFormat: 'Y-m-d H:i'}"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>

          <!-- Comments -->
          <b-form-group
            class="text-left"
            label-for="comment"
            label="Comment"
          >
            <validation-provider
              #default="{ errors }"
              name="Comment"
              vid="comment"
            >
              <b-form-textarea
                id="comment"
                v-model="comment"
                name="comment"
                :state="errors.length > 0 ? false:null"
                placeholder="Add Comment"
                rows="3"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2 d-flex">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span class="align-left ml-50">History</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
        >
          <template #cell(date_time)="data">
            {{ data.value }}
          </template>
          <template #cell(agent)="data">
            {{ data.value }}
          </template>
          <template #cell(action_outcome)="data">
            {{ data.value }}
          </template>
          <template #cell(stage)="data">
            {{ data.value }}
          </template>
          <template #cell(comments)="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-card>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BModal, VBModal, BCard, BFormTextarea, BForm, BFormInvalidFeedback, BFormGroup, BTable, BCardHeader, BCardTitle, // BBadge, BFormSelect,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import shareService from '@core/services/share/shareService'

export default {
  components: {
    BModal,
    BCard,
    BTable,
    BCardHeader,
    BCardTitle,
    // BBadge,
    // BAvatar,
    // BFormSelect,
    flatPickr,
    BFormGroup,
    BForm,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    refreshHandler: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Title: 'Action',
      id: 0,
      profile: {
        fullname: 'Andres Delgado Nunez',
        phone: '4623366427',
      },
      comment: '',
      catalogLists: {
        actions: [],
        interaction: [],
      },
      actionOutcome: '', // Selected Action Outcome
      interaction: '', // Selected Action Outcome
      schedule_date: '',
      required,
      fields: [
        { key: 'date_time', label: 'Date Time', sortable: true },
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'action_outcome', label: 'Action Outcome', sortable: true },
        { key: 'stage', label: 'Stage', sortable: true },
        { key: 'comments', label: 'Comment', sortable: true },
      ],
      items: [],
      isRescheduled: false,
    }
  },
  watch: {
    'actionOutcome.text': {
      handler(newName) {
        this.isRescheduled = newName === 'Scheduled'
        // Perform some action when 'name' property changes
      },
      deep: true, // Watch deep nested property
    },
  },
  created() {
    this.$bus.$on('modal-action', param => {
      const { profile, id, phone } = param.item
      this.profile.fullname = profile
      this.profile.phone = phone
      this.id = id
    })
  },
  methods: {
    init() {
      this.getCatalogs()
      this.loadLogs()
    },
    async getCatalogs() {
      const listCatalogs = Object.keys(this.catalogLists)
      listCatalogs.forEach(catalog => {
        this.getSpecificCatalog(catalog)
      })
    },
    loadLogs() {
      if (this.id === 0) return
      shareService.getSharedPatientChangelog({ id_partners_patient: this.id })
        .then(response => {
          const { data } = response.data
          this.items = data
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    resetModal() {
      this.actionOutcome = ''
      this.interaction = ''
      this.schedule_date = ''
      this.comment = ''
      this.catalogLists = {
        actions: [],
        interaction: [],
      }
      this.profile = {
        fullname: '',
        phone: '',
      }
    },
    async getSpecificCatalog(catalog) {
      return shareService.getAllElementCatalog({
        table: catalog,
      }).then(response => {
        const { data } = response.data
        this.catalogLists[catalog] = data
      }).catch(error => {
        this.toastErrorHandler(error)
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      this.$refs.statusValidation.validate().then(success => {
        if (success) {
          // Push the name to submitted names
          const action = {
            comment: this.comment,
            id_partner_patient: this.id,
            action: this.actionOutcome.value,
            interaction: this.interaction.value,
            schedule_date: this.schedule_date,
          }
          shareService.addPatientAction(action).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'The action has been set',
                icon: 'InboxIcon',
                variant: 'success',
              },
            })
            this.loadLogs()
            setTimeout(() => {
              this.refreshHandler()
              // Hide the modal manually
              this.$nextTick(() => {
                this.$refs['modal-action'].toggle('#toggle-btn')
              })
            }, 2 * 1000)
          }).catch(error => {
            this.toastErrorHandler(error)
          })
        }
      })

      // Hide the modal manually
    },
  },
}
</script>

    <style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
    .ContenAlign{
        display: flex;
        text-align: center;
        align-items: center;
        margin-bottom: 20px;
    }
    </style>
