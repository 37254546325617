<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-log"
    :title="Title"
    no-close-on-backdrop
    size="lg"
    :hide-footer="true"
    scrollable
    @hidden="resetModal"
    @show="init"
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <div>
        <h3>{{ profile.fullname }}</h3>
        <h6 class="text-muted">
          {{ profile.phone | phone-format }}
        </h6>
      </div>
      <hr class="mb-2">
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2 d-flex">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span class="align-left ml-50">Change Log</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
        >
          <template #cell(date_time)="data">
            {{ data.value }}
          </template>
          <template #cell(agent)="data">
            {{ data.value }}
          </template>
          <template #cell(action_outcome)="data">
            {{ data.value }}
          </template>
          <template #cell(stage)="data">
            {{ data.value }}
          </template>
          <template #cell(comment)="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-card>
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BTable,
} from 'bootstrap-vue'
  // import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import shareService from '@core/services/share/shareService'

export default {
  components: {
    BModal,
    BCard,
    BTable,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      Title: 'Change Log',
      id: 0,
      profile: {
        fullname: 'Andres Delgado Nunez',
        phone: '4623366427',
      },
      fields: [
        { key: 'date_time', label: 'Date Time', sortable: true },
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'action_outcome', label: 'Action Outcome', sortable: true },
        { key: 'stage', label: 'Stage', sortable: true },
        { key: 'comment', label: 'Comment', sortable: true },
      ],
      items: [],
    }
  },
  created() {
    this.$bus.$on('modal-log', param => {
      const { profile, id, phone } = param.item
      this.profile.fullname = profile
      this.profile.phone = phone
      this.id = id
    })
  },
  methods: {
    init() {
      this.loadLogs()
    },
    loadLogs() {
      if (this.id === 0) return
      shareService.getSharedPatientChangelog({ id_partners_patient: this.id })
        .then(response => {
          const { data } = response.data
          this.items = data
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    resetModal() {
      this.items = []
      this.profile = {
        fullname: '',
        phone: '',
      }
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .ContenAlign{
      display: flex;
      text-align: center;
      align-items: center;
      margin-bottom: 20px;
  }
  </style>
