<template>
  <!-- modal vertical center -->
  <b-modal
    id="modal-feedback"
    :title="Title"
    no-close-on-backdrop
    size="lg"
    :hide-footer="true"
    scrollable
    @hidden="resetModal"
    @show="init"
  >
    <b-card
      class="card-profile"
      style="margin-top:25px; margin-right: 20px; margin-left: 20px; background-color: #F9F9F9;"
    >
      <div
        class="profile-image-wrapper"
        style="margin-bottom: -35px;"
      >
        <div
          class="profile-image p-0"
          style="margin-top: 40px;"
        >
          <b-avatar
            size="35"
            variant="light"
          />
        </div>
      </div>
      <div
        style=""
      >
        <h3>{{ profile.fullname }}</h3>
        <h6 class="text-muted">
          {{ profile.phone | phone-format }}
        </h6>
      </div>
      <hr class="mb-2">
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2 d-flex">
            <feather-icon
              icon="FileTextIcon"
              size="18"
            />
            <span class="align-left ml-50">Follow-up</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="items"
          :fields="fields"
        >
          <template #cell(date_time)="data">
            {{ data.value }}
          </template>
          <template #cell(agent)="data">
            {{ data.value }}
          </template>
          <template #cell(comment)="data">
            {{ data.value }}
          </template>
        </b-table>
      </b-card>
    </b-card>
    <b-row>
      <b-col
        md="6"
        cols="2"
      />
      <b-col
        md="6"
        cols="2"
      >
        <div
          class="ContenAlign"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="openModalFeedback()"
          >
            Add new comment
          </b-button>
        </div>
      </b-col>
    </b-row>
    <patient-add-feedback-modal
      :refresh-handler="loadFeedback"
    />
  </b-modal>
</template>

<script>
import {
  BModal, VBModal, BCard, BButton, BTable, BRow, BCol, // BBadge, BFormSelect, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
  // import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import shareService from '@core/services/share/shareService'
import PatientAddFeedbackModal from '@/@core/components/modals/followup/PatientAddFeedbackModal.vue'

export default {
  components: {
    BModal,
    BCard,
    BTable,
    BRow,
    BCol,
    // BBadge,
    // BAvatar,
    // BFormSelect,
    // flatPickr,
    // BFormGroup,
    // BFormTextarea,
    PatientAddFeedbackModal,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      Title: 'Feedback',
      id: 0,
      profile: {
        fullname: 'Andres Delgado Nunez',
        phone: '4623366427',
      },
      fields: [
        // { key: 'idTicket', label: '#', sortable: true },
        { key: 'date_time', label: 'Date Time', sortable: true },
        { key: 'agent', label: 'Agent', sortable: true },
        { key: 'comment', label: 'Comment', sortable: true },
      ],
      items: [
        {
          date_time: '10/10/2023',
          agent: 'Sebastian Gutierrez',
          comment: 'Need to call ASAP',
        },
        {
          date_time: '10/11/2023',
          agent: 'Andrei Arias',
          comment: 'Cant call until next week',
        },
        {
          date_time: '10/13/2023',
          agent: 'Sebastian Gutierrez',
          comment: 'Dont forget before this month ends',
        },
      ],
    }
  },
  created() {
    this.$bus.$on('modal-feedback', param => {
      const { profile, id, phone } = param.item
      this.profile.fullname = profile
      this.profile.phone = phone
      this.id = id
    })
  },
  methods: {
    init() {
      this.loadFeedback()
    },
    loadFeedback() {
      if (this.id === 0) return
      shareService.getSharedPatientFeedback({ id_partners_patient: this.id })
        .then(response => {
          const { data } = response.data
          this.items = data
        }).catch(error => {
          this.toastErrorHandler(error)
        })
    },
    openModalFeedback() {
      this.$bus.$emit('add-comment-modal', { id: this.id })
      this.$root.$emit('bv::show::modal', 'add-comment-modal')
    },
    resetModal() {
      this.items = []
      this.fields = []
      this.profile = {
        fullname: '',
        phone: '',
      }
    },
  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
  .ContenAlign{
      display: flex;
      text-align: center;
      align-items: center;
      margin-bottom: 20px;
  }
  </style>
